<template>
  <div class="feature">
    <!-- <router-link :to="`/article/${data.id}`">
        
            <!-- -->
    <!-- <img :src="data.imgurl"> -->
    <!-- </router-link> -->
    <router-link target="_blank" :to="`/article/${data.id}`">
      <el-popover
        placement="top-start"
        title="标题"
        width="400"
        trigger="hover"
      >
        <div class="feature-title">
          <span class="foverlay">{{ data.title }}</span>
        </div>
        <img slot="reference" :src="data.imgurl" />
      </el-popover>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "feature",
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          title: "Akina",
          img:
            "https://cdn.zhebk.cn/usr/themes/Akina//images/feature/feature1.jpg",
          href: "https://zhebk.cn/Web/Akina.html"
        };
      }
    }
  }
};
</script>

<style scoped lang="less">
.feature {
  width: inherit;
  position: relative;
  img {
    height: 160px;
    width: 257px;
    object-fit: cover;
    border-radius: 50px;
    transition: all 0.6s;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
  }
  img:hover {
    transform: scale(1.2); /* 放大1.2倍 */
  }
  & a:hover .foverlay {
    opacity: 1;
  }
  .foverlay {
    position: absolute;
    z-index: 2;
    width: 257px;
    height: 160px;
    text-align: center;
    line-height: 160px;
    background: #bbe2db;
    color: white;
    font-size: 16px;
    opacity: 0;
    border-radius: 5px;

    -moz-transition: opacity 0.4s ease-out;
    -o-transition: opacity 0.4s ease-out;
    -webkit-transition: opacity 0.4s ease-out;
    transition: opacity 0.4s ease-out;
  }
}
</style>