import { render, staticRenderFns } from "./Empty.vue?vue&type=template&id=25b9495b&scoped=true&"
import script from "./Empty.vue?vue&type=script&lang=js&"
export * from "./Empty.vue?vue&type=script&lang=js&"
import style0 from "./Empty.vue?vue&type=style&index=0&id=25b9495b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25b9495b",
  null
  
)

export default component.exports