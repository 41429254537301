<template>
  <div class="home">
    <div class="site-content animate">
      <!--通知栏-->
      <banner isHome="true"></banner>

      <div class="notify">
        <div class="search-result" v-if="hideSlogan">
          <h3 v-if="searchWords">搜索结果："{{ searchWords }}" 相关文章</h3>

          <h3 v-else-if="category">分类 "{{ category }}" 相关文章</h3>
        </div>
        <!-- <quote v-else>{{ notice }}</quote> -->
      </div>

      <!--焦点图-->
      <div class="top-feature" v-if="!hideSlogan">
        <section-title>
          <div
            v-if="features.length > 0"
            style="display: flex; align-items: flex-end"
          >
            <h3>热门</h3>
            <small-ico></small-ico>
          </div>
        </section-title>

        <div class="feature-content bogoinfo">
          <div class="feature-item" v-for="item in features" :key="item.id">
            <Feature class="FeatureStyle" :data="item"></Feature>
          </div>
        </div>
      </div>

      <!--文章列表-->
      <main
        class="site-main"
        v-loading="loading"
        :class="{ search: hideSlogan }"
      >
        <el-row type="flex" justify="space-around">
          <el-col :span="span">
            <section-title v-if="!hideSlogan">
              <div style="display: flex; align-items: flex-end">
                <h3>推荐</h3>
                <small-ico></small-ico>
              </div>
            </section-title>

            <post
              v-for="item in Data"
              :key="item.id"
              class="bogoinfo"
              :post="item"
            ></post>

            <template v-if="PageModel.Total == 0">
              <Empty style="height: 200px" />
            </template>
            <!--加载更多-->
            <!-- <div class="more" v-show="hasNextPage">
              <div class="more-btn" @click="loadMore">More</div>
            </div>-->

            <footer
              v-if="PageModel.Total > 0"
              class="pagination wow fadeInDown"
              style="visibility: visible; animation-name: fadeInDown"
            >
              <ul>
                <li v-if="PageModel.PageIndex != 1">
                  <p @click="loadMore(1)">首页</p>
                </li>
                <li
                  v-for="item in PagesSum"
                  :key="item.id"
                  :class="{ active: item.isactive }"
                >
                  <span @click="loadMore(item.index)" v-if="item.isactive">
                    {{ item.index }}
                  </span>

                  <p @click="loadMore(item.index)" v-else>{{ item.index }}</p>
                </li>

                <li>
                  <p
                    v-if="PageModel.PageIndex < PageModel.PageNum"
                    @click="loadMore(PageModel.PageIndex + 1)"
                  >
                    下一页
                  </p>
                </li>
                <li>
                  <p
                    v-if="PageModel.PageIndex < PageModel.PageNum"
                    @click="loadMore(PageModel.PageNum)"
                  >
                    末页
                  </p>
                </li>
                <li>
                  <span>共 {{ PageModel.PageNum }}页</span>
                </li>
              </ul>
              <div id="loadmore" class="load-more-wrap loadmore">
                <h3
                  v-if="PageModel.PageIndex < PageModel.PageNum"
                  @click="loadMore(PageModel.PageIndex + 1)"
                  id="post_over"
                >
                  点击查看更多
                </h3>
              </div>
            </footer>
          </el-col>
          <el-col v-if="span != 24" :span="7">
            <div class="right_box">
              <BlogList v-loading="Blogarticleremesloading"  @refresh="Blogarticlerefresh"  :BlogarticleDto="BlogarticleDto"></BlogList>
            </div>

            <div class="right_box">
              <Homemsg v-loading="mesloading" @refresh="refresh" :MessageModelAsync="MessageModelAsync"></Homemsg>
            </div>

            <div class="right_box">
              <Userinfo></Userinfo>
            </div>
            
          </el-col>
        </el-row>
      </main>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/banner";
import Feature from "@/components/feature";
import Empty from "@/components/Empty";
import sectionTitle from "@/components/section-title";
import Post from "@/components/post";
import SmallIco from "@/components/small-ico";
import Quote from "@/components/quote";
import {
  BlogHotPage,
  BlogPageList,
  GetBloggetcount,
  MessageModelAsync,
  GetBlogarticleDtoAsync,
} from "../api/Resources";

export default {
  name: "Home",
  props: ["cate", "words"],
  data() {
    return {
      features: [],
      loading: false,
      mesloading:false,
      Blogarticleremesloading:false,
      postList: [],
      MessageModelAsync: [],
      BlogarticleDto: [],
      span: 18,
      screenWidth: document.body.clientWidth,
      PagesSum: {},
      Data: [],
      Blogcount: 0,
      Classcount: 0,
      Commentscount: 0,
      Labelscount: 0,
      Pagescount: 0,
      Viewscount: 0,
      hasNextPage: false,
      PageModel: {
        PageNum: 0,
        PageSize: 8,
        Total: 0,
        PageIndex: 1,
        OrderBy: "asc",
        className: "",
        Title: "",
      },
    };
  },
  components: {
    Banner,
    Feature,
    sectionTitle,
    Post,
    SmallIco,
    Quote,
    Empty,
    Userinfo: () => import("@/components/Userinfo"),
    Homemsg: () => import("@/components/Homemsg"),
    BlogList: () => import("@/components/BlogList"),
  },

  computed: {
    searchWords() {
      return this.$route.params.words;
    },
    category() {
      if (this.$route.query.eq != undefined) {
        this.PageModel.className = this.$route.query.eq;
      }
      return this.$route.params.cate;
    },

    hideSlogan() {
      return this.category || this.searchWords;
    },
    notice() {
      return this.$store.getters.notice;
    },
  },

  methods: {
    Blogarticlerefresh()
    {
       this.GetBlogarticleDtoAsync();
    },
    refresh()
    {
       this.GetMessageModelAsync();
    },
    fetchFocus() {
      BlogHotPage().then((res) => {
        this.features = res.rspdata || [];
      });
    },
    PageListInfo() {
      this.loading = true;
      BlogPageList(this.PageModel).then((res) => {
        this.PagesSum = [];
        this.Data = res.rspdata.data;
        this.PageModel.PageNum = res.rspdata.pagenum;
        this.PageModel.PageSize = res.rspdata.pagesize;
        this.PageModel.Total = res.rspdata.total;
        this.PageModel.PageIndex = res.rspdata.pageindex;
        this.hasNextPage =
          this.PageModel.PageIndex >= this.PageModel.PageNum ? false : true;
        for (let index = 1; index <= this.PageModel.PageNum; index++) {
          var data = new Object();
          data.isactive = this.PageModel.PageIndex == index ? true : false;
          data.title = "第" + index + "页";
          data.index = index;
          this.PagesSum.push(data);
        }
        this.loading = false;
      });
    },
    fetchList() {
      this.PageModel.Title = "";
      this.PageModel.className = "";
      if (this.searchWords != undefined) {
        this.PageModel.Title = this.searchWords;
      }
      if (this.$route.query.eq != undefined) {
        this.PageModel.className = this.$route.query.eq;
      }
      this.PageListInfo();
    },
    loadMore(num) {
      this.PageModel.PageIndex =
        num == this.PageModel.PageNum ? this.PageModel.PageNum : num;
      this.PageListInfo();
    },

    Bloggetcount() {
      GetBloggetcount().then((res) => {
        this.$nextTick((res1) => {
          this.Blogcount = res.rspdata.blogcount;
          this.Labelscount = res.rspdata.labelscount;
          this.Classcount = res.rspdata.classcount;
          this.Pagescount = res.rspdata.pagescount;
          this.Commentscount = res.rspdata.commentscount;
          this.Viewscount = res.rspdata.viewscount;
        });
      });
    },
    async GetMessageModelAsync() {
      this.mesloading=true;
      var res = await MessageModelAsync();
      this.MessageModelAsync = res.rspdata || [];
      this.mesloading=false;
    },
    async GetBlogarticleDtoAsync() {
      this.Blogarticleremesloading=true;
      var res = await GetBlogarticleDtoAsync();
      this.BlogarticleDto = res.rspdata || [];
      this.Blogarticleremesloading=false;
    },
    drawChart() {},
  },
  mounted() {
    this.$nextTick((ic) => {
      this.span = document.documentElement.clientWidth <= 1000 ? 24 : 18;
      this.fetchFocus();
      this.fetchList();
      //this.Bloggetcount();
      this.GetMessageModelAsync();
      this.GetBlogarticleDtoAsync();
      //this.drawChart();
    });
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        this.screenWidth = window.screenWidth;
      })();
    };
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.fetchList();
      },
      // 深度观察监听
      deep: true,
    },
    screenWidth(val) {
      this.span = document.documentElement.clientWidth <= 1000 ? 24 : 18;
    },
  },
};
</script>
<style scoped lang="less">
.site-content {
  .notify {
    margin: 60px 0;
    border-radius: 3px;
    & > div {
      padding: 20px;
    }
  }

  .search-result {
    padding: 15px 20px;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    border: 1px dashed #ddd;
    color: #828282;
  }
}
.right_box {
  width: 95%;
  float: right;
  overflow: hidden;
  margin-top: 20px;
  border-radius: 20px;
  margin-right: 5px;
}

.right_box1 {
  width: 95%;
  float: right;
  overflow: hidden;
  border: 2px dashed #ececec;
  margin-top: 20px;
  border-radius: 20px;
  margin-right: 5px;
}

.pagination {
  margin: 0 auto;
  text-align: left;
  font-size: 12px;
  display: block;
  overflow: hidden;
}

.FeatureStyle {
  margin-top: 30px;
}

.pagination ul {
  float: left;
}
.pagination ul li {
  display: inline;
}
.pagination ul li span {
  background-color: #ffffff;
  cursor: default;
  margin: 3px 5px 3px 0;
  float: left;
  padding: 7px 14px;
  border: 1px solid #ddd;
  font-size: 12px;
  color: #666;
  border-radius: 8px;
}
.pagination ul li.active span {
  color: #ffffff;
  background-color: rgb(54, 144, 207);
  border: 1px solid #3690cf;
}
.pagination ul li p {
  margin: 3px 5px 3px 0;
  float: left;
  padding: 7px 14px;
  background-color: #fff;
  border: 1px solid #ddd;
  font-size: 12px;
  color: #666;
  border-radius: 8px;
}

.pagination ul li p:hover,
.pagination ul li p:focus {
  // color: #4693e9;
  // text-decoration: none;
  cursor: pointer;
  color: #ffffff;
  background-color: rgb(54, 144, 207);
  border: 1px solid #3690cf;
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown; 
}

#loadmore {
  float: right;
  border-radius: 2px;

  #post_over {
    font-size: 14px;
    padding: 8px 20px;
    background-color: #fff;
    display: block;
    border-radius: 8px;
    color: #666;
    border: 1px solid #ddd;
    cursor: pointer;
  }

  #post_over p:hover,
  #post_over p:focus {
    // color: #4693e9;
    // text-decoration: none;
    cursor: pointer;
    color: #ffffff;
    background-color: rgb(54, 144, 207);
    border: 1px solid #3690cf;
  }
}

.rb_hot_click1 {
  position: relative;
  padding: 15px;
  cursor: pointer;
  overflow: hidden;
  margin-bottom: 15px;
  background: #fff;
  border: 1px solid #eee;
  vertical-align: bottom;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  border-radius: 8px;

  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    font-size: 14px;
  }

  .rb_hot_click_title1 {
    padding-bottom: 10px;
    padding-left: 15px;
    border-bottom: 2px solid #eee;
    margin-bottom: 10px;
    position: relative;
    font-size: 16px;
    z-index: 9;
    width: 100%;
  }

  ul li {
    color: #999;
    position: relative;
    margin-left: -30px;
    margin-right: 20px;
  }

  li:before {
    position: absolute;
    content: "";
    width: 3px;
    height: 3px;
    background: #4693e9;
    border-radius: 10px;
    left: 0px;
    top: 12px;
  }

  li {
    line-height: 25px;
    position: relative;
    display: inline-block;
    width: 50%;
    position: relative;
    padding-left: 10px;
  }

  .rb_hot_click_title1:before {
    content: "";
    position: absolute;
    left: 0px;
    top: 0;
    width: 3px;
    height: 22px;
    background-color: #00a4ff;
  }
}

.rb_hot_click {
  position: relative;
  padding: 15px;
  overflow: hidden;
  margin-bottom: 15px;
  background: #fff;
  border: 1px solid #eee;
  vertical-align: bottom;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  border-radius: 8px;

  .rb_hot_click_title {
    padding-bottom: 10px;
    padding-left: 15px;
    border-bottom: 1px solid #eee;
    margin-bottom: 10px;
    position: relative;
    font-size: 16px;
    z-index: 9;
  }
  .rb_hot_click_title:before {
    content: "";
    position: absolute;
    left: 0px;
    top: 0;
    width: 3px;
    height: 22px;
    background-color: #00a4ff;
  }

  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 50px;
  }
  ul,
  li,
  dl,
  dd,
  dt {
    list-style-type: none;
  }

  ul li {
    overflow: hidden;
    padding: 0px 0px;
    line-height: 40px;
    height: 40px;
    border-bottom: 1px #ddd solid;
    color: #999;
    position: relative;
    -moz-transition: none;
    -webkit-transition: none;
    -o-transition: none;
    -ms-transition: none;
    transition: none;
    margin-top: 1px;
  }

  ul li a {
    display: block;
    width: 86%;
    margin: 10px auto 0;
    height: 30px;
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #333;
    margin-left: 0px;
  }

  html,
  body,
  div,
  p,
  ul,
  ol,
  li,
  dl,
  dt,
  dd,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  form,
  input,
  select,
  button,
  textarea,
  iframe,
  table,
  th,
  td {
    margin: 0;
    padding: 0;
  }

  li {
    text-align: -webkit-match-parent;
  }

  ul li a .title {
    color: #333;
    font-size: 13px;
    // margin-block-start: 1.33em;
    // margin-block-end: 1.33em;
    // margin-inline-start: 0px;
    // margin-inline-end: 0px;
  }

  ul li .Userinfo {
    font-size: 13px;

    // margin-block-start: 1.33em;
    // margin-block-end: 1.33em;
    // margin-inline-start: 0px;
    // margin-inline-end: 0px;
  }

  #divComments {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    font-family: "Microsoft Yahei", Helvetica, Arial, sans-serif;
    line-height: 1.42857143;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: 0;
    border: 0;
    background: transparent;
    vertical-align: baseline;
    font-size: 100%;
    transition: ease-in-out 0.3s;
    margin-top: -8px;

    .divComments1 {
      position: relative;
      width: 100%;
      cursor: pointer;
      overflow: hidden;
      padding: 10px 00px 65px 60px;
      border: 0;
      border-bottom: 1px solid #ddd;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      background-image: none;
    }

    .zb_avatarp {
      line-height: 30px;
      height: 30px;
      margin-left: 10px;
      font-size: 14px;
      display: block;
      padding: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin: 0;
      padding: 0;
      -webkit-transition: 0.4s;
      -webkit-transition: -webkit-transform.4s ease-out;
      transition: transform.4s ease-out;
      -moz-transition: -moz-transform.4s ease-out;
      border: 1px solid #dfdfdf;
      -webkit-box-shadow: 0 0 10px #fff;
      box-shadow: 0 0 10px #fff;

      transition: all 0.6s;
    }
    img:hover {
      transform: scale(1.4);
    }

    .zb_avatar {
      position: absolute;
      left: 10px;
      top: 13px;
      width: 70px;
      height: 70px;
    }

    small {
      line-height: 20px;
      height: 20px;
      overflow: hidden;
      font-size: 11px;
      color: #999;
      display: block;
      border: 0;
      margin: 0;
      margin-left: 10px;
      padding: 0;

      .avatar_lf {
        display: inline-block;
        max-width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        .fa {
          display: inline-block;
          font: normal normal normal 14px/1 FontAwesome;
          font-size: inherit;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
        i.fa {
          padding-right: 3px;
        }

        .fa-user:before {
          content: "\f007";
        }
      }

      .right,
      .fr {
        float: right;
      }
    }
  }

  ul li a .serialNumber {
    display: block;
    width: 16px;
    height: 16px;
    float: left;
    background: #b1b1b1;
    position: relative;
    top: 7px;
    margin-left: 6px;
    margin-right: 4px;
    font-size: 9px;
    text-align: center;
    line-height: 16px;
    color: #fff;
    border-radius: 50px;
  }

  .serialNumber_cur {
    border-radius: 50px;
    background: #ff6600 !important;
  }
  .serialNumber_curred {
    border-radius: 50px;
    background: red !important;
  }
  .serialNumber_curthree {
    border-radius: 50px;
    background: blue !important;
  }
}

.bogoinfo {
  width: 100%;
  padding: 0 2.5% 20px;
  margin: 30px 0px 20px;
  overflow: hidden;
  border-bottom: 1px dashed #eee;
  position: relative;
  transition: ease-in-out 0.3s;
  border: 1px solid #ddd;
  position: relative;
  overflow: hidden;
  background: #fff;
  cursor: pointer;
  border-radius: 8px;
}

.top-feature {
  width: 100%;
  margin-top: 50px;

  .feature-content {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    position: relative;
    flex-wrap: wrap;
  }
}

.site-main {
  margin-top: 80px;

  &.search {
    padding-top: 0;
  }
}

.more {
  margin: 50px 0;
  .more-btn {
    width: 100px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #adadad;
    border: 1px solid #adadad;
    border-radius: 20px;
    margin: 0 auto;
    cursor: pointer;
    &:hover {
      color: #8fd0cc;
      border: 1px dashed #8fd0cc;
    }
  }
}

/******/
@media (max-width: 800px) {
  // #loadmore .top-feature {
  //   display: none;
  // }

  .top-feature {
    display: none;
  }

  // .site-main {
  //   padding-top: 40px;
  // }

  // .site-content {
  //   .notify {
  //     margin: 30px 0 0 0;
  //   }

  //   .search-result {
  //     margin-bottom: 20px;
  //     font-size: 16px;
  //   }
  // }
}

/******/
</style>
