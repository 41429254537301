<template>
  <div id="banner" v-loading="isloading" :class="{ 'home-banner': isHome }">
    <el-row :gutter="20">
      <el-col :span="15">
        <el-carousel
          style="width: 100%; border-radius: 15px"
          :interval="4000"
          direction="horizontal"
          :autoplay="true"
          indicator-position="none"
        >
          <el-carousel-item class="bg" v-for="item in imgList" :key="item.name">
            <img @click="urlclike(item.id)"
              class="bg bg2"
              :src="item.imgurl"
              style="height: 100%; width: 100%"
              :title="item.title"
            />
          </el-carousel-item>
        </el-carousel>
      </el-col>
      <el-col :span="9">
        <div data-v-c3b23e38 class="banner-right">
          <img data-v-c3b23e38 src="@/assets/say-bg.3808a5f.jpg" alt="每日一语" class="bg" />
          <div data-v-c3b23e38 class="banner-right-text">{{ note }}</div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { Dailsentence, GetBlogCarousel } from "../api/Resources";
export default {
  name: "banner",
  data() {
    return {
      websiteInfo: {},
      socials: [],
      note: "",
      imgList: [],
      isloading: false
    };
  },
  props: {
    src: {
      type: String,
      default: "https://s1.ax1x.com/2020/05/23/YxaLMq.jpg"
    },
    isHome: {
      type: [Boolean, String],
      default: false
    }
  },

  created() {
    // this.getWebSiteInfo();

    // this.getSocial();

    this.GetDailsentence();
  },
  methods: {
    getSocial() {
      this.$store.dispatch("getSocials").then(data => {
        this.socials = data.rspdata;
      });
    },
    getWebSiteInfo() {
      this.$store.dispatch("getSiteInfo").then(data => {
        this.websiteInfo = data.rspdata;
      });
    },
    GetDailsentence() {
      this.isloading = true;
      Promise.all([Dailsentence(), GetBlogCarousel()]).then(res => {
        this.note = res[0].rspdata.note;
        this.imgList = res[1].rspdata;
        this.isloading = false;
      });
    },
    urlclike(id) {
      let { href } = this.$router.resolve({ path: `/article/${id}` });
      window.open(href, "_blank");
    }
  }
};
</script>

<style scoped lang="less">
#banner {
  position: relative;
  margin-top: 150px;
  width: 100%;
  height: 300px;
  .box-card {
    overflow: hidden;
    margin-bottom: 10px;
  }
  .banner-img {
    width: inherit;
    height: inherit;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    transition: all 0.2s linear;
    overflow: hidden;
    &:hover {
      transform: scale(1.1, 1.1);
      filter: contrast(130%);
    }
  }
  &.home-banner {
    height: 300px;
    .banner-img {
      background-position: center center;
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-size: cover;
      z-index: -1;
      transition: unset;
      &:hover {
        transform: unset;
        filter: unset;
      }
    }
    .slant-left {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-bottom: 100px solid #fff;
      border-right: 800px solid transparent;
      left: 0;
      bottom: 0;
    }
    .slant-right {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-bottom: 100px solid #fff;
      border-left: 800px solid transparent;
      right: 0;
      bottom: 0;
    }
  }
}
.banner-right {
  font-family: PingFang SC, Helvetica Neue, Helvetica, Hiragino Sans GB,
    Microsoft YaHei, Arial, sans-serif;
  font-size: 14px;
  color: #333;
  padding: 0;
  float: right;
  width: 100%;
  height: 17pc;
  position: relative;
  border-radius: 3px;
  margin: 0px 0 0 2px;
}
.bg {
  display: block;
  width: 100%;
  height: 18.7pc;
  border-radius: 15px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
  transition: all 0.2s linear;
  border: 1px solid #dadada;
  overflow: hidden;
  cursor: pointer;
}
.bg2 {
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
}
.bg1 {
  &:hover {
    cursor: pointer;
    transform: scale(1.1, 1.1);
    filter: contrast(130%);
  }
}
.banner-right-text {
  margin: 0;
  padding: 0;
  width: 230px;
  height: 90pt;
  position: absolute;
  right: 20px;
  bottom: 50px;
  color: #f7b0b0;
  line-height: 25px;
  font-size: 1pc;
  font-family: \\5b8b\4f53;
  font-weight: 500;
}
.focusinfo {
  position: relative;
  max-width: 800px;
  padding: 0 10px;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  text-align: center;
  img {
    width: 80px;
    height: auto;
    border-radius: 50%;
    border: 3px solid rgba(255, 255, 255, 0.3);
  }
  .header-info {
    width: 100%;
    color: black;
    font-family: miranafont, "Hiragino Sans GB", STXihei, "Microsoft YaHei",
      SimSun, sans-serif;
    letter-spacing: 1px;
    line-height: 30px;
    font-size: 12;
  }
}

.webinfo {
  font-family: miranafont, "Hiragino Sans GB", STXihei, "Microsoft YaHei",
    SimSun, sans-serif;
  padding: 5px;
  font-size: 15px;
  font-weight: bold;
}
.top-social {
  height: 32px;
  margin-top: 10px;
  margin-left: 10px;
  list-style: none;
  display: inline-block;
  font-family: miranafont, "Hiragino Sans GB", STXihei, "Microsoft YaHei",
    SimSun, sans-serif;
  div {
    float: left;
    margin-right: 10px;
    height: 32px;
    width: 50px;
    line-height: 32px;
    text-align: center;
    width: 32px;
    border-radius: 100%;
  }
}
@media (max-width: 960px) {
  #banner {
    height: 400px;
  }
}
@media (max-width: 800px) {
  #banner {
    display: none;
  }
}
</style>