<template>
  <span
    @click="collectShow()"
    :class="[
      'iconfont',
      'post-more-span',
      collect === false ? 'icon-buoumaotubiao45' : 'icon-shoucang',
      showDonate === false ? 'eventsauto' : 'eventsnone'
    ]"
  ></span>
</template>
<script>
export default {
  name: "Collect",
  props: {
    showDonate: {
      required: false,
      type: Boolean,
      default: false
    },
    collect: {
      required: false,
      type: Boolean,
      default: false
    },
    post: {
      required: false,
      type: Object
    }
  },
  methods: {
    collectShow() {
      this.$emit("childFn", this.post);
    }
  }
};
</script>

<style scoped>
.post-more-span {
  color: red;
  font-size: 20px;
  cursor: pointer;
}
.post-more-span:hover {
  color: blue;

  font-size: 25px;
}
.eventsnone {
  pointer-events: none;
}
.eventsauto {
  pointer-events: auto;
}
</style>

